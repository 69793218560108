// assets
import { IconDashboard, IconSwitchHorizontal, IconCreditCard } from '@tabler/icons-react';

// constant
const icons = { IconDashboard, IconSwitchHorizontal, IconCreditCard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'main',
  title: 'Dashboard',
  type: 'group',
  children: [
    {
      id: 'overview',
      title: 'Overview',
      type: 'item',
      url: '/overview',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'transactions',
      title: 'Transactions',
      type: 'item',
      url: '/transactions',
      icon: icons.IconSwitchHorizontal
    },
    {
      id: 'cards',
      title: 'Cards',
      type: 'item',
      url: '/cards',
      icon: icons.IconCreditCard
    }
  ]
};

export default dashboard;
