import React, { lazy } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

// project imports
import GuestGuard from './../utils/route-guard/GuestGuard';
import MinimalLayout from './../layout/MinimalLayout';
import NavMotion from './../layout/NavMotion';
import Loadable from '../ui-component/Loadable';

// routing
const ForgotPassword = Loadable(lazy(() => import('../views/pages/authentication/forgotPassword')));
const ResetPassword = Loadable(lazy(() => import('../views/pages/authentication/resetPassword')));

//-----------------------|| AUTH ROUTING ||-----------------------//

const OtherRoutes = () => {
    const location = useLocation();

    return (
        <Route path={['/forgot-password', '/reset-password']}>
            <MinimalLayout>
                <NavMotion>
                    <Routes>
                        <Route path="/forgot-password" exact component={ForgotPassword} />
                        <Route path="/reset-password/:token" component={ResetPassword} />
                    </Routes>
                </NavMotion>
            </MinimalLayout>
        </Route>
    );
};

export default OtherRoutes;
