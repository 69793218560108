import React, { lazy } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from './../utils/route-guard/AuthGuard';

// dashboard routing
const Home = Loadable(lazy(() => import('../views/main/Home')));
const Create = Loadable(lazy(() => import('../views/main/Create')));
const Set = Loadable(lazy(() => import('../views/main/Set')));
const Play = Loadable(lazy(() => import('../views/main/Play')));
const Practice = Loadable(lazy(() => import('../views/main/Practice')));
const Settings = Loadable(lazy(() => import('../views/main/Settings')));
const Upgrade = Loadable(lazy(() => import('../views/main/Upgrade')));
const Page404 = Loadable(lazy(() => import('../views/errors/404')));


//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();

    return (
        <Route
            path={[
                '/home',
                // '/settings',
                '/upgrade',

                '/create',
                '/set/:id',
                '/set/:id/play',
                '/set/:id/practice',

                '/test-prep'
                // '*'
            ]}
        >
            <AuthGuard>
                <MainLayout>
                    <Routes location={location} key={location.pathname}>
                        <Route path="/home" exact component={Home} />
                        {/* <Route path="/settings" exact component={Settings} /> */}
                        <Route path="/upgrade" exact component={Upgrade} />

                        <Route path="/create" exact component={Create} />
                        <Route path="/set/:id" exact component={Set} />
                        <Route path="/set/:id/play" exact component={Play} />
                        <Route path="/set/:id/practice" exact component={Practice} />

                        <Route path="/test-prep" exact component={Upgrade} >
                            <Route path="/a" component={Upgrade} />
                        </Route>

                        {/* <Route path="*" component={Page404} /> */}
                    </Routes>
                </MainLayout>
            </AuthGuard>
        </Route>
    );
};

export default MainRoutes;
