import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import jwt_decode from 'jwt-decode'
import axiosHttp from '../axios';
//-----------------------|| AUTH GUARD ||-----------------------//

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    console.log('auth guard')
    const dispatcher = useDispatch();

    const account = useSelector((state) => state.account);
    const { isLoggedIn } = account;

    console.log('account token: ', account.token)


    axiosHttp.interceptors.response.use(response => {
        return response;
    }, error => {
        if (error.response.status === 401) {
            dispatcher({ type: 'LOGOUT' })
        }
        return Promise.reject(error);
    });

    // if (!document.cookie.includes('session')) {
    // dispatcher({type: 'LOGOUT'})
    // }
    // check if token expired
    // if (account.token) {

    //     let decodedToken = account.token //jwt_decode(account.token);
    //     console.log("Decoded Token", decodedToken);
    //     let currentDate = new Date();

    //     // JWT exp is in seconds
    //     if (decodedToken.exp * 1000 < currentDate.getTime()) {
    //         console.log("Token expired.");
    //         dispatcher({ type: 'LOGOUT' })
    //     } else {
    //         console.log("Valid token");
    //     }
    // }

    const location = useLocation();

    if (!isLoggedIn) {
        return <Navigate to="/login" />;
    }

    return children ? children : <Outlet />;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
