// assets
import { IconHelp } from '@tabler/icons-react';
import config from '../config.js';

// constant
const icons = { IconHelp };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const contact = {
  id: 'contact',
  title: 'contact',
  type: 'group',
  children: [
    {
      id: 'support',
      title: 'Support',
      type: 'item',
      url: config.supportUrl,
      icon: icons.IconHelp,
      external: true,
      target: true
    }
  ]
};

export default contact;
