import dashboard from './dashboard';
import plan from './plan';
import contact from './contact';
// import pages from './pages';
// import utilities from './utilities';
// import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  // items: [dashboard, pages, utilities, other]
  items: [dashboard, plan, contact]
};

export default menuItems;
