import axios from 'axios'
import config from '../../config'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';



const axiosHttp = axios.create({
    baseURL: `${config.API_SERVER}`,
    withCredentials: true
});

// axios.interceptors.response.use(response => {
//     return response;
// }, error => {
//     if (error.response.status === 401) {
//         history.push('/login');
//     }
//     return error;
// });


export default axiosHttp;