import React, { useState } from 'react';


const NotificationContext = React.createContext({
    severity: null,
    text: null,
    info: () => { },
    success: () => { },
    warning: () => { },
    error: () => { },
    clear: () => { },
});


const NotificationProvider = (props) => {
    const [severity, setSeverity] = useState(null);
    const [text, setText] = useState(null);

    const info = (text) => {
        setText(text);
        setSeverity('info');
    };

    const success = (text) => {
        setText(text);
        setSeverity('success');
    };

    const warning = (text) => {
        setText(text);
        setSeverity('warning');
    };

    const error = (text) => {
        setText(text);
        setSeverity('error');
    };

    const clear = () => {
        setText(null);
        setSeverity(null);
    };

    return (
        <NotificationContext.Provider
            value={{
                info, success, warning, error, clear, severity, text,
            }}
        >
            {props.children}
        </NotificationContext.Provider>
    );
};

export { NotificationProvider };
export default NotificationContext;