import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from './routes';

// defaultTheme
import theme from './themes';

// project imports
import { NotificationProvider } from './context/notificationContext';
import NotificationBar from './ui-component/Notification';
import NavigationScroll from './layout/NavigationScroll';
import { LOGOUT } from './store/actions';
import axios from 'axios'
import { RouterProvider } from 'react-router-dom'

import { router } from './routes';
//-----------------------|| APP ||-----------------------//

const App = () => {
    const customization = useSelector((state) => state.customization);
    const account = useSelector((state) => state.account);
    const dispatcher = useDispatch();
    // dispatcher({type: LOGOUT})

    // useEffect(() => {
    //     fetch("/api/validate-token", { headers: { Authorization: account.token } }).then(response => {
    //         return response.status
    //     }).then((status) => {
    //         console.log(status)
    //         if (status != 200) {
    //             //  token is invalid -> force logout
    //             dispatcher({ type: LOGOUT });
    //         }
    //     })
    // }, [])

    return (
        <React.StrictMode>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme(customization)}>
                    <NotificationProvider>
                        <NotificationBar />
                        <CssBaseline />
                        {/* <NavigationScroll> */}
                        {/* <Routes /> */}
                        <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
                        {/* </NavigationScroll> */}
                    </NotificationProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </React.StrictMode>
    );
};

export default App;
