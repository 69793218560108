import { useState, useContext, forwardRef } from 'react';
import { Snackbar, Alert, IconButton } from '@material-ui/core'
import { IconX } from '@tabler/icons-react';
import NotificationContext from '../../context/notificationContext';

const NotificationBar = () => {
    const notificationCtx = useContext(NotificationContext);

    const notificationAction = (
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
        >
            <IconX fontSize="small" />
        </IconButton>
    )

    const CustomAlert = forwardRef(function CustomAlert(props, ref) {
        return <Alert ref={ref} variant="filled" {...props} />;
    });

    return (
        notificationCtx.severity !== null && (
            <>
                <Snackbar
                    open={true}
                    autoHideDuration={3000}
                    action={notificationAction}
                    onClose={notificationCtx.clear}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >

                    <CustomAlert onClose={notificationCtx.clear} severity={notificationCtx.severity} sx={{ width: '100%' }}>
                        {notificationCtx.text}
                    </CustomAlert>

                </Snackbar>
            </>
        )
    );
}; export default NotificationBar;