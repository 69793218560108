// assets
import { IconFolder, IconArrowUpCircle } from '@tabler/icons-react';

// constant
const icons = { IconFolder, IconArrowUpCircle };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const plan = {
  id: 'plan',
  title: 'plan',
  type: 'group',
  children: [
    {
      id: 'billing',
      title: 'Manage',
      type: 'item',
      url: '/plan',
      icon: icons.IconFolder
    },
    {
      id: 'plans',
      title: 'Upgrade',
      type: 'item',
      url: '/upgrade',
      icon: icons.IconArrowUpCircle
    }
  ]
};

export default plan;
