import React, { lazy } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

// project imports
import GuestGuard from './../utils/route-guard/GuestGuard';
import MinimalLayout from './../layout/MinimalLayout';
import NavMotion from './../layout/NavMotion';
import Loadable from '../ui-component/Loadable';

// login routing
const AuthLogin = Loadable(lazy(() => import('../views/pages/authentication/login')));
const AuthRegister = Loadable(lazy(() => import('../views/pages/authentication/register')));

//-----------------------|| AUTH ROUTING ||-----------------------//

const LoginRoutes = () => {
    const location = useLocation();

    return (
        <React.Fragment>
            <GuestGuard>
                <MinimalLayout>
                    <NavMotion>
                        <Routes>
                            <Route path="/login" exact component={AuthLogin} />
                            <Route path="/register" exact component={AuthRegister} />
                        </Routes>
                    </NavMotion>
                </MinimalLayout>
            </GuestGuard>
        </React.Fragment>
    );
};

export default LoginRoutes;
